import React, { useEffect, useRef, useState } from 'react'
import Loader from '../../components/Loader/loader'
import { Link } from 'react-router-dom'
import { getApi, postApi } from '../service/lenderService'
import toast from 'react-hot-toast'
import PaginationComponent from '../../PaginationComponent'
import Search from '../../components/Search/Search'
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import { Button } from 'reactstrap'

const EWArepayment = () => {
    const exportfix = process.env.REACT_APP_API_URL + process.env.REACT_APP_LENDER_PRIFIX
    const lender = JSON.parse(localStorage.getItem("lender"));
    const ref = useRef()
    const inputref = useRef()
    const dropdownRef = useRef(null);
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [dataArray, setDataArray] = useState([])
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [selected, setselected] = useState('All')
    const [totalPaidAmount, settotalPaidAmount] = useState(0)
    const [totalRemainingAmount, settotalRemainingAmount] = useState(0)
    const [excelFile, setExcelFile] = useState(null);
    const [uploadFile, setUploadFile] = useState(null)
    const [selectedborrower, setselectedborrower] = useState('');

    const viewReconciliationDetail = async () => {
        try {
            const response = await getApi('/ewa-advance-reconciliation', { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
            if (response.status) {
                setTimeout(() => {
                    setDataArray(response?.data?.rows);
                    setTotalPagesCount(response?.data?.count)
                    settotalPaidAmount(response?.data?.totalPaidAmount)
                    settotalRemainingAmount(response?.data?.totalRemainingAmount)
                    setloader(false)
                }, 2000);

                setloader(true)
            } else {
                console.log(response.message)
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        viewReconciliationDetail()
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const HandleFile = (e) => {
        const pdf = e.target.files[0];
        if (pdf && pdf.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            toast.error('Invalid file type. Please upload a valid Excel file (XLSX format).');
            e.target.value = '';
            setUploadFile(null);
            return;
        }
        setUploadFile(pdf);
        readExcel(pdf)
    }

    const HandleReminderFile = (e) => {
        const file = e.target.files[0]
    }

    const uploadExcelForm = useFormik({
        initialValues: {
            isExcelFile: false,
            wrong_message: ""
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // employer_id: yup.string().required('Please enter Amount'),
            // isExcelFile: yup.boolean().oneOf([true], ""),

        }),

        onSubmit: async (values) => {
            if (uploadFile) {
                const formData = new FormData();
                formData.append("file", uploadFile);
                const response = await postApi("/upload-repayment-update-sheet", formData, true);
                if (response.status == true) {
                    toast.success(response.message);
                    uploadExcelForm.setFieldValue("isExcelFile", "")
                    setExcelFile(null)
                    setselected('All')
                    setUploadFile(null)
                    setselectedborrower("")
                    inputref.current.value = ""
                    uploadExcelForm.resetForm()
                    ref.current.click()
                    viewReconciliationDetail()
                } else {
                    inputref.current.value = ""
                    toast.error(response.message);
                }

            }

        },
    })

    const HandlClose = () => {
        uploadExcelForm.setFieldValue("isExcelFile", "")
        setExcelFile(null)
        setselected('All')
        setUploadFile(null)
        setselectedborrower("")
        inputref.current.value = ""
    }

    const handleCheckboxChange = (event) => {
        const { value } = event.target;
        setselected(value);
    };

    const handleCheckboxChanges = (User_Loan_ID) => {
        setselectedborrower((prevSelected) => {
            const currentSelected = prevSelected?.split(',').map(id => id.trim()).filter(Boolean);
            if (currentSelected.includes(User_Loan_ID.toString())) {
                return currentSelected?.filter(id => id !== User_Loan_ID?.toString()).join(',');
            } else {
                return [...currentSelected, User_Loan_ID].join(',');
            }
        });
    };

    const dropdownMenuStyle = {
        position: 'absolute',
        zIndex: 1000,
        backgroundColor: 'white',
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '2px 23px',
        maxHeight: '233px',
        overflowY: 'auto',
        display: isOpen ? 'block' : 'none',
    };

    const dropdownContentStyle = {
        maxHeight: '150px',
        overflowY: 'auto',
    };

    const readExcel = (file) => {
        if (file) {
            const promise = new Promise((resolve, reject) => {
                if (file) {
                    let reader = new FileReader();
                    reader.readAsArrayBuffer(file);

                    reader.onload = (e) => {
                        const bufferArray = e.target.result;
                        const workBook = XLSX.read(bufferArray, { type: 'buffer' });
                        const workSheetName = workBook.SheetNames[0];
                        const workSheet = workBook.Sheets[workSheetName];
                        const data = XLSX.utils.sheet_to_json(workSheet);
                        resolve(data)
                    }
                    reader.onerror = (error) => {
                        reject(error)
                    }
                }
            })
            promise.then((d) => {
                setExcelFile(d)
            })

        } else {
            console.log('please select your file');

        }
    }

    const [searchQuery, setSearchQuery] = useState('');
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };
    const filteredData = dataArray.filter(item => {
        const fullName = `${item.first_name} ${item.last_name}`.toLowerCase();
        const mobileNumber = item.mobile_number.toLowerCase();
        // const id = item?.user_loan_id?.toLowerCase();
        return fullName.includes(searchQuery.toLowerCase()) || mobileNumber.includes(searchQuery.toLowerCase()) /* || id.includes(searchQuery.toLowerCase()) */;
    });

    // Count checked items
    const checkedCount = selectedborrower?.split(',').map(id => id?.trim()).filter(id => filteredData.some(item => item.user_loan_id.toString() === id)).length || 0;

    const AddReminder = useFormik({
        initialValues: {
            reminderMessage: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            reminderMessage: yup.string().required('Please Enter Message'),

        }),
        onSubmit: async (values, { resetForm }) => {
            let response
            response = await postApi(`/edit-ewa-reminder-template`, {
                reminderMessage: values.reminderMessage
            })
            if (response.status == true) {
                toast.success(response.message)

                // handleRef.current.click()
                resetForm({ values: "" })
                // setGetReminderDetail({})
            } else {
                toast.error(response.message)
                console.log(response.message)
            }
        }
    })

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Paid</h4> */}

                    <h4 className="page-title mb-0 text-primary ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a>EWA Advance</a></li>
                                <li className="breadcrumb-item active" aria-current="page">EWA Repayment</li>
                            </ol>
                        </nav>
                    </h4>
                </div>
                <div className="page-rightheader">
                    <button to="" className="btn btn-primary btn-pill"
                        data-toggle="modal" data-target="#modal-center"
                    ><i className="fa fa-plus" /> Bulk Repayments Updates</button>                  
                </div>
                {/* <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true"> */}
                <div className="modal fade" id="modal-center" tabIndex="-1" role="dialog" aria-labelledby="modal-centerLabel" aria-hidden="true">

                    <div className="modal-dialog modal-dialog-centered emplyoyemodal modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="modal-centerLabel">Upload Repayments Updates</h5>
                                <button ref={ref} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => HandlClose()}> <i className='fas fa-close' /> </button>

                            </div>
                            <div className="modal-body">
                                <div className="formbox row">
                                    <form className="" onSubmit={uploadExcelForm.handleSubmit}  >
                                        <div className="row ms-3 mb-3 d-flex justify-content-around">
                                            <div className="col-lg-12 ">
                                                <input
                                                    className=""
                                                    type="checkbox"
                                                    value="All"
                                                    checked={selected === 'All'}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label ms-3" style={{ fontSize: 'medium' }}>For All Borrower</label>
                                                <input
                                                    className="ms-3"
                                                    type="checkbox"
                                                    value="Check"
                                                    checked={selected === 'Check'}
                                                    onChange={handleCheckboxChange}
                                                />
                                                <label className="form-check-label ms-3 fs-16" style={{ fontSize: 'medium' }}>Check Mark Specific</label>
                                            </div>
                                        </div>
                                        <div className="row ">

                                            <div className="col-sm-8 container">
                                                {selected === "Check" &&
                                                    <div className="dropdown" style={{ position: 'relative' }} ref={dropdownRef}>
                                                        <input
                                                            type="text"
                                                            className="form-control mb-1"
                                                            placeholder="Select Borrower"
                                                            onClick={toggleDropdown}
                                                            style={{ cursor: 'pointer', paddingRight: '10px', border: '1px solid #121089' }}
                                                            readOnly
                                                        />
                                                        <span style={{
                                                            position: 'absolute',
                                                            right: '10px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            fontWeight: 'bold',
                                                            color: '#121089'
                                                        }}>
                                                            {checkedCount}
                                                        </span>
                                                        <span onClick={toggleDropdown} style={{
                                                            position: 'absolute',
                                                            right: '20px',
                                                            top: '50%',
                                                            transform: 'translateY(-50%)',
                                                            cursor: 'pointer',
                                                            fontSize: '16px',
                                                            color: '#121089'
                                                        }}>
                                                            {isOpen ? '▼' : '▲'}
                                                        </span>
                                                        {isOpen && selected === 'Check' && (
                                                            <div style={dropdownMenuStyle}>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Search by name or mobile number"
                                                                    value={searchQuery}
                                                                    onChange={handleSearchChange}
                                                                    style={{ marginBottom: '5px' }}
                                                                />

                                                                <div style={dropdownContentStyle}>
                                                                    {filteredData.length > 0 ? (
                                                                        filteredData.map(item => (
                                                                            <div key={item.id} className="form-check">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id={`lender-${item.user_loan_id}`}
                                                                                    className="form-check-input"
                                                                                    checked={selectedborrower?.split(',').map(id => id?.trim()).includes(item?.user_loan_id?.toString())}
                                                                                    onChange={() => handleCheckboxChanges(item.user_loan_id)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    style={{ fontSize: 'medium', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                                                                    htmlFor={`lender-${item.user_loan_id}`}
                                                                                >
                                                                                    <div>{item?.user_loan_id ? "ID: " + item?.user_loan_id + " --" : ""} </div>
                                                                                    <div>{item.first_name ? item.first_name : "--"} {item.last_name ? item.last_name + " --" : ""}</div>
                                                                                    <div>{item.mobile_number ? item.mobile_number : ""}</div>
                                                                                </label>
                                                                            </div>
                                                                        ))
                                                                    ) : (
                                                                        <div className='text-bold'>No Applicant Available</div>
                                                                    )}
                                                                </div>

                                                            </div>
                                                        )}
                                                    </div>}
                                            </div>
                                            <div className='col-sm-5 mb-2 container'>
                                                {selected == "All" || (selected == "Check" && selectedborrower != "") ? <Link to={exportfix + `/export-pending-repayments?lender_id=${lender?.user_id}&user_loan_id=${selected == "All" ? "" : selectedborrower}`}
                                                    onClick={() => { setIsOpen(false); setselectedborrower('') }}
                                                    type="submit" className="btn btn-primary" /* style={{ width: '13rem' }} */ download><i className='fas fa-download' /> Sample Download</Link> :
                                                    <button type="submit" className="btn btn-primary" style={{ cursor: 'none' }} disabled ><i className='fas fa-download' /> Sample Download</button>}
                                            </div>
                                            <div className="col-sm-12">

                                                <div className="col-sm-9 page-rightheader container"/*  style={{ marginLeft: '10px' }} */>
                                                    <div className="btn-list ">
                                                        <button className="btn btn-outline-primary uploadbmodal">
                                                            <label htmlFor="formFile">
                                                                <i className="fa fa-upload fa-beat me-2"></i>
                                                            </label>
                                                            <input ref={inputref} type="file" id='formFile' onChange={(e) => HandleFile(e)} />
                                                        </button>
                                                    </div>
                                                </div>

                                                {uploadFile && excelFile?.length > 0 && <p className="fs-11 text-danger mt-2"><strong>Note:*</strong> Please verify the repayment details below carefully. Once confirmed, click on "Save And Update" to proceed.</p>}
                                                {uploadFile && excelFile?.length > 0 && <div className="table-responsive" style={{ overflowY: 'scroll', height: '200px' }}>
                                                    <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */ >
                                                        <thead className="border-bottom-0 pt-3 pb-3">
                                                            <tr>
                                                                <th className="font-weight-bold">Ewa ID.</th>
                                                                <th className="font-weight-bold">First Name</th>
                                                                <th className="font-weight-bold">Disbursed Amt</th>
                                                                <th className="font-weight-bold">Repayment Amt</th>
                                                                <th className="font-weight-bold">Due Date</th>
                                                                <th className="font-weight-bold">Received</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            {excelFile && excelFile.length > 0 && excelFile.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { EwaID, FirstName, LastName, DisbursedLoanAmount, RepaymentAmount, DueDate, Received } = option

                                                                const backgroundColor = Received === "Yes" ? "yellow" : "#d85b5b"; // Adjust colors as needed
                                                                return (
                                                                    <tr key={serial_num} style={{ backgroundColor, color: 'black', padding: '10px', margin: '5px 0' }} >
                                                                        <td><span className="font-weight-normal1">{option?.EwaID ? EwaID : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.FirstName ? FirstName : "--"} {option.LastName ? LastName : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.DisbursedLoanAmount ? DisbursedLoanAmount : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.RepaymentAmount ? RepaymentAmount : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.DueDate ? DueDate : "--"}</span></td>
                                                                        <td><span className="font-weight-normal1">{option.Received ? option.Received == "-- SELECT --" ? "No" : Received : "--"}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>}

                                                <div className="col-md-12" style={{ marginBlock: '2rem' }}>
                                                    <div className="modalboxEmployee">
                                                        <button type="submit" className="btn btn-primary w-md" > Save and Update</button>
                                                        <button ref={ref} type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => HandlClose()}> Close </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <svg className="card-custom-icon header-icon text-primary text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill=""> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                            <p className="fs-13 mb-1"> Received Amount</p>
                            <h4 className="mb-1 font-weight-bold text-success">
                                {typeof totalPaidAmount == 'string'
                                    ? "$" + parseFloat(totalPaidAmount).toFixed(2)
                                    : typeof totalPaidAmount === 'number'
                                        ? "$" + totalPaidAmount.toFixed(2)
                                        : "0"}
                            </h4>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-lg-6 col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                            <p className="fs-13 mb-1">Remaining Amount</p>
                            <h4 className="mb-1 font-weight-bold text-warning">
                                {typeof totalRemainingAmount == 'string'
                                    ? "$" + parseFloat(totalRemainingAmount).toFixed(2)
                                    : typeof totalRemainingAmount === 'number'
                                        ? "$" + totalRemainingAmount.toFixed(2)
                                        : "0"}
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-6 col-md-12">
                    {/* <div className="card">
                                    <div className="card-body">
                                        <svg className="card-custom-icon header-icon text-primary" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"> <path d="M0 0h24v24H0V0z" fill="none"></path> <path d="M11.99 18.54l-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16zm0-11.47L17.74 9 12 13.47 6.26 9 12 4.53z"></path> </svg>
                                        <p className="fs-13 mb-1">Earning</p>
                                        <h4 className="mb-1 font-weight-bold">{typeof totalItems?.investedAmount === 'string'
                                            ? "$" + parseFloat(totalItems.investedAmount).toFixed(2)
                                            : typeof totalItems?.investedAmount === 'number'
                                                ? "$" + totalItems.investedAmount.toFixed(2)
                                                : "0"}
                                        </h4>
                                    </div>
                                </div> */}
                </div>

                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12" /> */}
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                </div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                </div>
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
                <label className="form-label">Search</label>
                <div className="row g-xs">
                    <div className="input-group">
                        <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                        <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                    </div>
                </div>
            </div>
        </div> */}
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card visiableCard">
                        <div className="card-body p-0 visiableCard">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                        <tr>
                                            <th className="font-weight-bold">Loan ID</th>
                                            <th className="font-weight-bold">Customer Details  {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                            <th className="font-weight-bold">Actual Amount</th>
                                            <th className="font-weight-bold">EWA Advance Amount {/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                            <th className="font-weight-bold">Amount Paid {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                            <th className="font-weight-bold">Amount Outstanding  {/* {sortedBy == "due_date" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('due_date') }}> </i>} */} </th>
                                            <th className="font-weight-bold">Pending Repayment  {/* {sortedBy == "late_days" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('late_days') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('late_days') }}> </i>} */} </th>
                                        </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <tr><td /><td /> <td />
                                            <Loader /></tr>
                                    </tbody> :
                                        <>
                                            <tbody>
                                                {dataArray?.length > 0 && dataArray?.map((option, index) => {
                                                    const { first_name, last_name, mobile_number, total_paid_amount, total_remaining_amount, pending_repayment_count, upcoming_repayment_date, last_repayment_date } = option;
                                                    const symbol = option?.loanDisbursedData?.currency == "INR" ? "₹" : "$";
                                                    return (
                                                        <tr key={index} >
                                                            <td><span className="font-weight-normal1"> {option?.user_loan_id} </span></td>
                                                            <td><span className="font-weight-normal1" ><span className="font-weight-normal1">{option?.first_name ? first_name : ""} {option?.last_name ? last_name : ""}<br />{option?.mobile_number ? mobile_number : ""}</span></span></td>
                                                            <td><span className=""> {option?.loanDisbursedData?.amount ? symbol + option?.loanDisbursedData?.amount : "--"} </span></td>

                                                            <td><span className="font-weight-normal1"> {option?.loanDisbursedData?.total_repayment_amount ? symbol + option?.loanDisbursedData?.total_repayment_amount : "--"} </span></td>
                                                            <td><span className="font-weight-normal1"> {option?.total_paid_amount ? symbol + total_paid_amount : "--"}</span></td>
                                                            <td><span className="font-weight-normal1"> {option?.total_remaining_amount ? symbol + total_remaining_amount : "--"} </span></td>
                                                            <td className="topToBottom"><span className="font-weight-normal1"> Total Repayent Count: {option?.loanDisbursedData?.total_repayment_number ? option?.loanDisbursedData?.total_repayment_number : "--"}
                                                                <br />No. of Pending repayment: {option?.pending_repayment_count ? pending_repayment_count : "--"} <br />
                                                                Date of Upcoming Repayment: {option?.upcoming_repayment_date ? upcoming_repayment_date : "--"}<br />
                                                                Cycle: {option?.loanDisbursedData?.repayment_type ? option?.loanDisbursedData?.repayment_type : "--"}<br />
                                                                Repayment Amount: {option?.loanDisbursedData?.repayment_amount ? symbol + option?.loanDisbursedData?.repayment_amount : "--"}<br />
                                                                Last Repayment Date: {option?.last_repayment_date ? last_repayment_date : "--"}<br />

                                                                {/* <button className='btn btn-primary'>View More</button> */}

                                                            </span>
                                                                <div className="dropdown">
                                                                    <button className="btn btn-primary dropdown-toggle btn-sm"
                                                                        type="button"
                                                                        id="dropdownMenuButton"
                                                                        aria-expanded="false">View More</button>
                                                                    <table
                                                                        className="dropdown-menu"
                                                                        aria-labelledby="dropdownMenuButton"
                                                                        style={{
                                                                            overflowY: 'scroll',
                                                                            maxHeight: '160px',
                                                                            marginTop: '20px'
                                                                        }}
                                                                    > <thead>

                                                                            <tr >
                                                                                <th>Dates</th>
                                                                                <th>Amount</th>
                                                                                <th>Status</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {option?.loanEmiData?.length > 0 && option?.loanEmiData?.sort((a, b) => new Date(a.due_date) - new Date(b.due_date)).map((Items, index) => {
                                                                                const { due_date, emi_number, amount, status } = Items;
                                                                                const dueDate = new Date(due_date);
                                                                                const today = new Date();
                                                                                let backgroundColor = "white";
                                                                                let handleStatus = "false"
                                                                                if (status == "PENDING") {
                                                                                    if (dueDate < today) {
                                                                                        backgroundColor = "#d95b5b";
                                                                                        handleStatus = 'true'
                                                                                    } else {
                                                                                        backgroundColor = "#efef58";
                                                                                    }
                                                                                }
                                                                                return (
                                                                                    <tr key={index} style={{ backgroundColor }}>
                                                                                        <td>{Items?.due_date ? due_date : "--"}</td>
                                                                                        <td>{Items?.amount ? symbol + amount : "--"}</td>
                                                                                        <td>{Items?.status ? handleStatus == "true" ? "Overdue" : status : "--"}</td>
                                                                                    </tr>)
                                                                            })}
                                                                        </tbody>
                                                                    </table>
                                                                </div></td>
                                                        </tr>

                                                    )
                                                }
                                                )}
                                            </tbody>
                                            <tbody>
                                                {dataArray?.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Paid Transaction Data Available</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems={totalPagesCount}
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
        </div>
    )
}

export default EWArepayment