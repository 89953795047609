import React, { useEffect, useRef, useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { GetPermission, getApi, postApi } from '../../service/lenderService';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PaginationComponent from '../../../PaginationComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';
import moment from 'moment';
import { TimeZone } from '../../../components/TimeZone/TimeZone';

const Addsettlement = () => {
  const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
  const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
  const tableRef = useRef(null);
  const [dataArray, setDataArray] = useState([]);
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [copied, setCopied] = useState(false);
  const [search, setSearch] = useState('');
  const [merchantData, setMerchantData] = useState([])
  const { id } = useParams();
  const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
  const [transactionAmount, setTransactionAmount] = useState([]);
  const [merchantId, setMerchantId] = useState('');
  const [totalAmoumt, setTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [paymentMode, setPaymentMode] = useState('');
  const [disable, setDisable] = useState(false);
  const [multippleArray, setMultippleArray] = useState([]);
  const [datas, setDatas] = useState([])
  const [imageUpload, setimageUpload] = useState(null)
  const [sortedBy, setSortedBy] = useState("id")
  const [orderBy, setOrderBy] = useState('desc')
  const [loader, setloader] = useState(true)
  const prefix = process.env.REACT_APP_LENDER_PRIFIX;
  const navigate = useNavigate()
  const getAllPendingSettlementt = async () => {
    // const response = await GetAllTransactionsByMerchanttId(token,id ,search , pagesCount, sortedBy , orderBy  );
    const response = await getApi(`/pending-settalemnt-by-merchant/${id}`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });

    // console.log(response)
    if (response.status == true) {
      setTimeout(() => {
        setDataArray(response.PendingSettalments)
        // setData(response.PendingSettalmentById)
        setMerchantData(response.merchantData)
        setTotalPagesCount(response.totalPendingSettalment)
        setDatas(response.PendingSettalments[0].merchant_id)
        setloader(false)
      }, 2000);
      setloader(true)
    } else if (response.message == 'Session expired') {
      localStorage.removeItem('lender');
      navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
    } else if (response.message == "Access denied! you're not allowed to access this tab") {
      toast.error(response.message)
      navigate('/lender/dashboard')
    }

  }

  useEffect(() => {
    getAllPendingSettlementt(token, id, search, pagesCount)

  }, [pagesCount, sortedBy, orderBy])

  useEffect(() => {
  }, [transactionPrimaryId, transactionAmount, merchantId, paymentMode, allChecked])

  const addSettlementForm = useFormik({
    initialValues: {
      merchant_id: "",
      merchantTransctionId: "",
      payment_mode: '',
      comment: "",
      image: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      comment: yup.string().required('Please add a comment'),
      payment_mode: yup.string().required('Please add a payment mode '),
    }),
    onSubmit: async values => {
      const formData = new FormData();
      formData.append("merchant_id", datas);
      formData.append("merchantTransctionId[]", multippleArray);
      formData.append("payment_mode", addSettlementForm.getFieldProps('payment_mode').value);
      formData.append("comment", addSettlementForm.getFieldProps('comment').value);
      formData.append("image", imageUpload);
      // AddSettlement(formData)
      postApi("/add-settlement", formData, true).then((res) => {
        if (res.status == true) {
          toast.success("Settlement Done Successfully");
          // window.location.reload(true)
          // window.location.reload();           
          getAllPendingSettlementt(token, id, search, pagesCount)
          setMultippleArray([])
          setTotalAmount(0)
          //   setIsChecked(false)
          //  console.log(setIsChecked)
        }
      }).catch((err) => {

      })
    }
  });

  const selectTransactions = async (e, id, amount) => {
    let checkArray = await multippleArray;
    let rowIndex = checkArray.findIndex(v => v == id);

    if (rowIndex > -1) {

      let newAmount = parseFloat(totalAmoumt) - parseFloat(amount);
      setTotalAmount(newAmount)
      checkArray.splice(rowIndex, 1);

    } else {
      checkArray.push(id);
      let newAmount = parseFloat(totalAmoumt) + parseFloat(amount)
      setTotalAmount(newAmount)
    }

    setMultippleArray(checkArray);
    setDataArray((old_data) => [...old_data]);

    if (dataArray.length == multippleArray.length) {
      setIsChecked(true);
    }
    if (dataArray.length != multippleArray.length) {
      setIsChecked(false);
    }
  }

  function handleCheckClick() {
    if (isChecked == false) {
      let newdata = dataArray.map(v => parseInt(v.id)).join(',')
      let arrayData = newdata.split(',').map(Number);

      let newAmount = (dataArray.reduce((a, v) => a = a + parseFloat(v.merchant_settled_amount), 0))
      setTotalAmount(newAmount)
      setMultippleArray((old_data) => [...arrayData]);
      setIsChecked(true);
    } else {
      setTotalAmount(0)
      setIsChecked(false);
      setMultippleArray([]);
    }
    setDataArray((old_data) => [...old_data]);
  }


  const sortChange = (col) => {
    if (col === sortedBy) {
      setSortedBy(col);
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
    } else {
      setSortedBy(col)
      setOrderBy("desc")
    }
    // getEmisData(token,   search  , pagesCount );
  }

  const checheked = (item) => {
    return multippleArray.includes(item) ? 1 : 0;

  }

  const handleInputChange = (event) => {
    addSettlementForm.handleChange(event);
    if (event?.target?.value) {
      setDisable(true)
    }
  }

  var num3 = parseFloat(totalAmoumt).toFixed(2)

  const copyinfo = () => {
    toast.success("Transaction ID copied Successfully")
  }

  const [permission_data, setPermission_data] = useState([])

  const GetPermissions = async (token) => {
    try {
      const response = await GetPermission(token);
      console.log('response', response);
      if (response.status) {
        setPermission_data(response.data);
      }
    } catch (error) {
      // toast.error("error :", error.message);
      console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
    }
  }
  const user_types = JSON.parse(lenderData)
  const user_type = user_types.user_type

  useEffect(() => {
    if (user_type == "SUB-LENDERS") {
      GetPermissions(token);
    }
  }, []);
  const permissions = permission_data?.permissions;
  if (permissions) {
    var role = JSON.parse(permissions);
  } else {

  }

  const [merchant_add_settlement, setmerchant_add_settlement] = useState(false)

  useEffect(() => {
    if (role) {
      const options = role;
      console.log(options)
      options.length > 0 && options.forEach((option) => {
        if (option.key == "merchant_add_settlement") {
          setmerchant_add_settlement(true);
        }
      });
    }
  }, [role]);

  return (

    <div className="main-container container-fluid px-0">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="page-header mb-3">
        <div className="page-leftheader">
          <h4 className="page-title mb-0 text-primary">Merchant Name: <span className=''> {merchantData.company_name} </span>  </h4>
          {/* <img className='ms-5' style={{ width: 80, height: 80, borderRadius: '8px' }} alt="..."
         src={'https://thirdroc1.s3.ap-southeast-2.amazonaws.com/' + 'logo/' + merchantData.logo} /> 
         
               */}
        </div>
        <div style={{ marginLeft: '450px', marginTop: '10px' }}>
          <h4 className="ml-2">{totalAmoumt ? `Total: ${num3}` : ""}</h4>
        </div>
        {(user_type == 'lenders' || merchant_add_settlement) && (<div className='page-rightheader'>
          <button className='btn btn-primary' data-bs-target="#add_settlement"
            data-bs-toggle="modal" disabled={multippleArray.length > 0 ? false : true} >+ Add Settlement</button>
        </div>)}
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                  <thead className="border-bottom-0 pt-3 pb-3">
                    <tr>
                      {(user_type == 'lenders' || merchant_add_settlement) && <>
                        {dataArray.length > 0 && <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>}</>}
                      {/* <th className="font-weight-bold">Sr No.</th> */}
                      <th className="font-weight-bold">Transaction ID  {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}></i>}</th>
                      <th className="font-weight-bold">Borrower Detail {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}></i>}</th>
                      <th className="font-weight-bold">Employer   {sortedBy == "employer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }}></i>}</th>
                      <th className="font-weight-bold">Amount  {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }}></i>}</th>
                      <th className="font-weight-bold">Merchant Amount  {sortedBy == "merchant_settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }}></i>}</th>
                      <th className="font-weight-bold">Lender Commission  {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }}></i>}</th>
                      <th className="font-weight-bold">Transaction Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}></i>}</th>
                      {/* <th className="font-weight-bold">STATUS</th> */}
                    </tr>
                  </thead>
                  {loader == true ? <tbody>
                    <tr>
                    <td /><td /><td /><td />                   
                    <Loader /> </tr>
                  </tbody> :
                    <>
                      <tbody>
                        {dataArray && dataArray.map((option, index) => {
                          // var timezone = new Date(option.created_at).toLocaleString(undefined, {timeZone: 'Asia/Kolkata', hour12:true  });

                          return (
                            <tr key={index} >
                              {(user_type == 'lenders' || merchant_add_settlement) && <>
                                {dataArray.length > 0 && <td><input type="checkbox" name={option.id} checked={checheked(option.id) === 1 ? true : false} onChange={(e) => selectTransactions(e, option.id, option.merchant_settled_amount)} /></td>}</>}
                              <td><span className="font-weight-normal1">{option.txn_id}</span>
                                <span>
                                  <CopyToClipboard text={option.txn_id} onCopy={() => setCopied(true)}>
                                    <button className='btn btn-primary btn-sm ms-3' onClick={() => copyinfo()}><i className="fa-solid fa-copy"></i></button>
                                  </CopyToClipboard>
                                </span>
                              </td>
                              <td><Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${option.mobile_number}/Existing` }}><span className="font-weight-normal1">{option.first_name ? option.first_name : "--"}<br />{option.mobile_number}</span></Link></td>
                              <td><Link className="text-primary text-primary" to={{ pathname: `${prefix}/employer_detail/${option.employer_id}/Existing` }}><span className="font-weight-normal1">{option.employer_name ? option.employer_name : "--"}<br />{option.employer_mobile}</span></Link></td>
                              <td><Link className="text-primary" to={{ pathname: `${prefix}/emibyorderid/${option.id}`, state: { id } }}><span className="font-weight-normal1">{option.paid_amount ? "$" + option.paid_amount : ""}</span> </Link></td>
                              <td><span className="font-weight-normal1">{option.merchant_settled_amount ? "$" + option.merchant_settled_amount : ""}</span></td>
                              <td><span className="font-weight-normal1">{option.lender_commission_amount ? "$" + option.lender_commission_amount  /* + "(" + option.thirdroc_roi + "%)" */ : ""}</span></td>
                              <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>

                            </tr>
                          )
                        })}
                      </tbody>
                    </>}
                </table>
              </div>
            </div>
          </div>
        </div>
        {pagesCount > 0 && dataArray.length > 0 ?
          <>
            <div className="col-md-12">
              <div className="card-body">
                <PaginationComponent className="justify-content-center"
                  totalItems={totalPagesCount}
                  pageSize={10}
                  maxPaginationNumbers={3}
                  onSelect={(e) => { setpagesCount(e); setTotalAmount(0); setIsChecked(false); setMultippleArray([]); }}
                />
              </div>
            </div>
          </>
          : ''}
      </div>
      <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content modal-content-demo">
            <div className="modal-header">
              <h3 className="modal-title">Settlement</h3>
              <button aria-label="Close" className="btn-close" data-bs-dismiss="modal" type="button"><span
                aria-hidden="true">×</span></button>
            </div>
            <div style={{ marginTop: '20px', marginLeft: '20px' }}>
              <h4> {totalAmoumt ? `Total: ${totalAmoumt}` : ""}</h4>
            </div>
            <div className="modal-body application-modal">

              <form className="mt-5 row" onSubmit={addSettlementForm.handleSubmit}>
                {addSettlementForm.values.wrong_message ?
                  <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                  : ''}
                <div className="row">
                  <div className="row g-xs">
                    <div className="col-12">
                      <select className="form-control" name="payment_mode"
                        {...addSettlementForm.getFieldProps("payment_mode")}
                        placeholder="Enter Payment Mode"  >
                        <option value="null">Select One</option>
                        <option value="cash" >Cash</option>
                        <option value="cheque">Cheque</option>
                        <option value="bank transfer">Bank Transfer</option>
                      </select>
                      {addSettlementForm.touched.payment_mode && addSettlementForm.errors.payment_mode ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.payment_mode}</div> : ''}
                    </div>
                    <div className="col-12">
                      <input type="text" onKeyUp={(e) => handleInputChange(e)} placeholder='comment' {...addSettlementForm.getFieldProps("comment")} value={addSettlementForm?.comment?.values} className="form-control" />
                      {addSettlementForm.touched.comment && addSettlementForm.errors.comment ?
                        <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.comment}</div> : ''}
                    </div>
                    <div className="input-group file-browser mb-5">
                      <input type="file" className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                    </div>
                  </div>
                  <div className="form-footer mt-2">
                    <button type="submit" className="btn btn-primary" data-bs-dismiss={"modal"} aria-hidden="true" disabled={!disable ? true : false}>
                      Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Addsettlement
