import Swal from "sweetalert2";

const handleDeleteAlert = (items) => {
    Swal.fire({
        title: "Deletion Not Allowed!",
        text: `You must retain at least one ${items} before deletion.`,
        icon: "info"
      });
}

export default handleDeleteAlert