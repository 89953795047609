import React, { useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate } from 'react-router-dom'
import PaginationComponent from '../../PaginationComponent'
import Loader from '../../components/Loader/loader'
import { getApi, postApi } from '../service/adminService'
import { TimeZone } from '../../components/TimeZone/TimeZone'
import { useFormik } from 'formik'
import * as yup from 'yup';

const Reminder = () => {
    const [ValidPagination, setValidPagination] = useState(false)
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [Count, setCount] = useState();
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const handleRef = useRef()
    const [getReminderDetail , setGetReminderDetail] = useState({})
    const [handleStatus , setHandleStatus] = useState("new")

    const ViewLoanCases = async () => {
        const response = await getApi(`/ewa-reminder-template`, {search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewLoanCases()

    }, [search, pagesCount, sortedBy, orderBy])
    
    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    } 

    const AddReminder = useFormik({
        initialValues:{
            reminderTitle:  getReminderDetail?.reminderTitle || "",
            reminderDays:  getReminderDetail?.reminderDays || "",
            reminderMessage: getReminderDetail?.reminderMessage || "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
                 reminderTitle: yup.string().required('Please Enter Title'),
                 reminderDays: yup.string().required('Please Enter Days'),
                 reminderMessage: yup.string().required('Please Enter Message'),     

        }),
        onSubmit:async  (values, { resetForm }) => {
            let response
            if (handleStatus == "new") {
                response = await postApi('/create-ewa-reminder-template',{
                    reminderTitle:values.reminderTitle,
                    reminderDays:values.reminderDays,
                    reminderMessage:values.reminderMessage
     })
            } else {
                response = await postApi(`/edit-ewa-reminder-template/${getReminderDetail?.id}`,{                   
                    reminderTitle:values.reminderTitle,
                    reminderDays:values.reminderDays,
                    reminderMessage:values.reminderMessage
     })
            }
           
if(response.status == true){
    toast.success(response.message)
    ViewLoanCases()
    handleRef.current.click()
    resetForm({ values: "" })
    setGetReminderDetail({})
}else{
    toast.error(response.message)
    console.log(response.message)
}
        }
    })

    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">Reminder Templete {Count ? "(" + Count + ")" : ""}</h4>
                            </div>
                            <div className="page-rightheader">
                                <div className="btn-list">
                                    <button to="" className="btn btn-primary btn-pill"
                                    onClick={() => setHandleStatus('new')}
                                    data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="fa fa-add me-2"></i> Add Reminder </button>
                                </div>
                            </div>
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="staticBackdropLabel">{handleStatus == "new" ? "Add Reminder Templete" : "Edit Reminder Templete"}</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form onSubmit={AddReminder.handleSubmit}>
        <div className='row'>
          <div class="col-md-7">
            <label for="recipient-name" class="col-form-label">Title:</label>
            <input type="text" class="form-control" {...AddReminder.getFieldProps('reminderTitle')} id="recipient-name"/>
            {AddReminder.touched.reminderTitle && AddReminder.errors.reminderTitle ?
        <div className="invalid-feedback" style={{ display: "block" }}>{AddReminder.errors.reminderTitle}</div> : ''}
          </div>
          <div class="col-md-5">
            <label for="message-text" class="col-form-label">Reminder Days:</label>
            <input class="form-control" type="number" {...AddReminder.getFieldProps('reminderDays')}></input>
            {AddReminder.touched.reminderDays && AddReminder.errors.reminderDays ?
        <div className="invalid-feedback" style={{ display: "block" }}>{AddReminder.errors.reminderDays}</div> : ''}
          </div> </div>
          <div class="mb-3">
            <label for="message-text" class="col-form-label">Message:</label>
            <textarea class="form-control" cols={3} id="message-text" {...AddReminder.getFieldProps('reminderMessage')}></textarea>
            {AddReminder.touched.reminderMessage && AddReminder.errors.reminderMessage ?
         <div className="invalid-feedback" style={{ display: "block" }}>{AddReminder.errors.reminderMessage}</div> : ''}
          </div>
             <div class="modal-footer">
        <button type="button" class="btn btn-dark" data-bs-dismiss="modal" ref={handleRef}>Close</button>
        <button type="submit" class="btn btn-primary">{handleStatus == "new" ? "Add " : "Edit"}</button>
      </div>      
        </form>
      </div>
      
    </div>
  </div>
</div>
                        </div>
                        <div className="row">
                           
                                <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"/> 
                                 <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12"/>                                      
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => { setSearch(e.target.value); setpagesCount('1'); setValidPagination(!ValidPagination) }} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} /> */}
                                        
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>                                                        
                                                        <th className="font-weight-bold">Title</th>
                                                        <th className="font-weight-bold">Message  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Reminder Days {sortedBy == "lender_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_id') }}> </i>}</th>
                                                       <th className="font-weight-bold">Created Date  {sortedBy == "createdAt" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('createdAt') }}> </i>}</th>
                                                       <th className="font-weight-bold">Updated Date  {sortedBy == "updated_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('updated_at') }}> </i>}</th>
                                                        <th className="font-weight-bold">Action </th>
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><tr>
                                                   <td /><td /><Loader /> </tr></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const {id, reminderTitle,reminderDays,reminderMessage } = option;

                                                                return (
                                                                    <tr key={serial_num} >                                                                     
                                                                        <td><span className='font-weight-normal1'>{option?.reminderTitle ? reminderTitle : "-"}</span></td>
                                                                        <td><span className="font-weight-normal1"> {option?.reminderMessage ? reminderMessage : "- -"} </span></td>
                                                                        <td><span className="font-weight-normal1"> {option?.reminderDays || option?.reminderDays == 0 ? reminderDays : "--"} </span></td>                                                                       
                                                                        <td><span className="font-weight-normal1">{/* {timezone} */}{TimeZone(option?.created_at).timeZone}</span></td>
                                                                        <td><span className="font-weight-normal1">{/* {timezone} */}{TimeZone(option?.updated_at).timeZone}</span></td>
                                                                        <td><button className="btn btn-primary" onClick={() => {setGetReminderDetail(option); setHandleStatus("edit")}}
                                                                             data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                                                            <span className="font-weight-normal1">Edit</span></button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>    
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}
                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}


                        </div>
                    </div>

                 
                </div>
            }
        </>
    )
}

export default Reminder
