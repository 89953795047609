import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react'
import { getApi, postApi } from '../../../service/lenderService';
import * as yup from 'yup';
import Loader from '../../../../components/Loader/loader';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

const MlAnalyser = ({ setAnalyserModal, analyserModal, mlData, setRemainingDetail, remainingDetail, GetMLanalysis }) => {
    const params = useParams()
    const [viewAIdata, setViewAIData] = useState([])
    const [spinnerStatus, setSpinnerStatus] = useState(false)
    const [updatechatStatus, setUpdateChatStatus] = useState(false)
    const [dbchatresponse, setDBchatresponse] = useState([])
    const [handleAPI , setHandleAPI] = useState(false)

    const AddForm = useFormik({
        initialValues: {
            prompt: "",
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // prompt: yup.string().required('Please Enter Prompt here'),

        }),

        onSubmit: async (values, { resetForm }) => {
            setSpinnerStatus(true)
            let requestData = {};
            let isContinue = false
            if (viewAIdata.length == 0) {
                requestData = {
                    "id": mlData,
                    "openai_key": "sk-proj-xCZUsYD1xSQIsxcSYEhRT3BlbkFJXQLr5aHInXiyuEiXvdY3",
                    "prompt": values.prompt,
                    "bucket_name": "thirdroc-openai-chat-files",
                    "file_key": mlData
                };
                let tempArr = []
                if (dbchatresponse?.length == 0) {
                    tempArr = [{ isUser: false, message: 'How can I help you today?' }]
                }
                tempArr = [...tempArr, { isUser: true, message: values.prompt }]

                setViewAIData([
                    ...viewAIdata,
                    ...tempArr
                ])

            } else {
                isContinue = true
                requestData = {
                    "id": mlData,
                    "openai_key": "sk-proj-xCZUsYD1xSQIsxcSYEhRT3BlbkFJXQLr5aHInXiyuEiXvdY3",
                    "assistant_id": remainingDetail?.assistant_id,
                    "file_id": remainingDetail?.file_id,
                    "thread_id": remainingDetail?.thread_id,
                    "prompt": values.prompt
                };
                setViewAIData([
                    ...viewAIdata,
                    { isUser: true, message: values.prompt }
                ])
            }
            axios.request({
                method: "POST",
                url: !isContinue ? 'https://3tmio416r7.execute-api.ap-southeast-2.amazonaws.com/v1/assistant/start' : 'https://3tmio416r7.execute-api.ap-southeast-2.amazonaws.com/v1/assistant/continue',
                data: requestData,
                headers: {
                    "x-api-key": 'chUkpBamc36taj1oHYQkP8i6PMPe3S1Y3tD9MwfI'
                },
            })

                .then((response) => {
                    postMLanalysis("lender", values.prompt)
                    setTimeout(() => {
                         UpdateChat(values.prompt)
                         console.log("hedhrehrehrehjerjwrejweueuhytr")
                    }, 10000);
                    resetForm({ values: '' });
                }).catch((error) => {
                    console.error(error);
                })
        }

    });
    const HandleStopAIChat = async () => {
        AddForm.setFieldValue('prompt', "")
        setViewAIData([])

        axios.request({
            method: "POST",
            url: 'https://3tmio416r7.execute-api.ap-southeast-2.amazonaws.com/v1/assistant/stop',
            data: {
                "id": mlData,
                "openai_key": "sk-proj-xCZUsYD1xSQIsxcSYEhRT3BlbkFJXQLr5aHInXiyuEiXvdY3",
                "assistant_id": remainingDetail?.assistant_id,
                "file_id": remainingDetail?.file_id,
                "thread_id": remainingDetail?.thread_id,
                "bucket_name": "thirdroc-openai-chat-files",
                "file_key": mlData
            },
            headers: {
                "x-api-key": 'chUkpBamc36taj1oHYQkP8i6PMPe3S1Y3tD9MwfI'
            },
        }).then((response) => {
            console.log(response.data);
        }).catch((error) => {
            console.error(error);
        })
    }


    // const UpdateChat = async (userLatestMsg) => {   
    //     try {
    //         const response = await postApi('/ml-analysis-response', {
    //             "jsonFileName": mlData
    //         })
    //         setSpinnerStatus(false)
    //         if (response?.mainResponse?.responses?.length > 0) {
    //             const modifiedResponse = response?.mainResponse?.responses.map((data) => {
    //                 return { isUser: false, message: data }
    //             })
    //             for (const data of modifiedResponse) {
    //                 postMLanalysis("ML", data.message)
    //             }

    //             if (viewAIdata?.length == 0 && dbchatresponse?.length == 0) {
    //                 setViewAIData([
    //                     ...viewAIdata,
    //                     { isUser: false, message: 'How can I help you today?' },
    //                     { isUser: true, message: userLatestMsg },
    //                     ...modifiedResponse
    //                 ])
    //                 setRemainingDetail(response?.mainResponse)
    //             }
    //             else {

    //                 setViewAIData([
    //                     ...viewAIdata,
    //                     { isUser: true, message: userLatestMsg },
    //                     ...modifiedResponse
    //                 ])
    //                 setRemainingDetail(response?.mainResponse)
    //             }

    //         } else if (!updatechatStatus) {
    //             setSpinnerStatus(true)
    //             setTimeout(() => {
    //                  UpdateChat(userLatestMsg)
    //                 console.log("actual function")
    //                 StoreErrorLogs(response?.mainResponse?.error)
    //             }, 15000);
    //         }
    //     } catch (error) {
    //         console.log(error,"*********")
    //     }
    // }

    // POST API For Store Error Logs 
    
    
    const timeoutRef = useRef(null);
    const intervalRef = useRef(null);
    const startTimeRef = useRef(null);


    const UpdateChat = async (userLatestMsg) => {
        try {
            const response = await postApi('/ml-analysis-response', {
                "jsonFileName": mlData
            });
            setSpinnerStatus(false);

            if (response?.mainResponse?.responses?.length > 0) {
                const modifiedResponse = response?.mainResponse?.responses.map((data) => {
                    return { isUser: false, message: data };
                });
                for (const data of modifiedResponse) {
                    postMLanalysis("ML", data.message);
                }

                if (viewAIdata?.length === 0 && dbchatresponse?.length === 0) {
                    setViewAIData([
                        ...viewAIdata,
                        { isUser: false, message: 'How can I help you today?' },
                        { isUser: true, message: userLatestMsg },
                        ...modifiedResponse
                    ]);
                    setRemainingDetail(response?.mainResponse);
                } else {
                    setViewAIData([
                        ...viewAIdata,
                        { isUser: true, message: userLatestMsg },
                        ...modifiedResponse
                    ]);
                    setRemainingDetail(response?.mainResponse);
                }

                clearInterval(intervalRef.current);
                startTimeRef.current = null; 
            } else {
                if (!updatechatStatus) {
                    setSpinnerStatus(true);
                    if (!startTimeRef.current) {
                        startTimeRef.current = Date.now(); 
                    }

                    clearInterval(intervalRef.current);

                    intervalRef.current = setInterval(() => {
                        const elapsedTime = Date.now() - startTimeRef.current;
                        if (elapsedTime < 40000) { 
                            UpdateChat(userLatestMsg);
                            StoreErrorLogs(response?.mainResponse?.error);
                            // console.log("working...........");
                        } else {
                            toast.error("Something Went Wrong Please Try Again");
                            clearInterval(intervalRef.current); 
                            setSpinnerStatus(false);
                            setAnalyserModal(false);
                            
                        }
                    }, 15000);
                }
            }
        } catch (error) {
            console.log(error, "*****************************");
        }
    };

    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);
    
    
    const StoreErrorLogs = async(error) => {
        try {
             const response = await postApi('/save-ml-error',{
              user_loan_id: params.id,
              error: error
        })
        if(response.status){
            console.log(response.message)
        }else{
            console.log(response.message)
        }
        } catch (error) {
            console.log(error)
        }
       
    }

    const postMLanalysis = async (usertype, message) => {
        const response = await postApi('/save-ml-response', {
            "user_loan_id": params.id,
            "message": message,
            "user_type": usertype
        })
        if (response.status) {
            console.log(response.message, "internaltest")
        } else {
            console.log(response, "error")
        }
    }


    const getMLAnalysis = async () => {
        const response = await getApi(`/get-db-ml-response/${params.id}`)
        if (response.status) {
            setDBchatresponse(response.data)

        }
    }

    const chatContainerRef = useRef(null);

    useEffect(() => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }, []); 

    const scrollToBottom = () => {
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    };



    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    <h4 className="page-title mb-0 text-primary">ML Analysis </h4>
                </div>
                <div className="page-rightheader">
                    <div className="btn-list">
                        {!analyserModal ? (
                            <button
                                className='btn btn-primary'
                                onClick={() => {
                                    setAnalyserModal(true);
                                    getMLAnalysis();
                                    GetMLanalysis();
                                }}
                            >
                                Start Analysing
                            </button>
                        ) : (
                            <>
                                {viewAIdata.length === 0 ? (
                                    <>
                                        <button
                                            className="btn btn-sm btn-primary btn-pill"
                                            onClick={() => {
                                                setAnalyserModal(false);
                                            }}
                                            disabled={spinnerStatus}
                                        >
                                            Close
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        {spinnerStatus ? (
                                            <button
                                                className="btn btn-sm btn-danger btn-pill"
                                                onClick={() => {
                                                    setAnalyserModal(false);
                                                    HandleStopAIChat();
                                                    setUpdateChatStatus(true)
                                                    setHandleAPI(false)
                                                }}

                                            >
                                                Stop chat
                                            </button>
                                        ) : (
                                            <button
                                                className="btn btn-sm btn-danger btn-pill"
                                                onClick={() => {
                                                    setAnalyserModal(false);
                                                    setUpdateChatStatus(true)
                                                    HandleStopAIChat();
                                                    setHandleAPI(false)
                                                }}
                                            >
                                                End Chat
                                            </button>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            {analyserModal &&
                <>
                    <div className="AIforbehavior chat-container" ref={chatContainerRef}>
                        {viewAIdata?.length == 0 && dbchatresponse?.length == 0 &&
                            <div className='d-flex justify-content-start'>
                                <h6 className='mx-2'
                                    style={{
                                        fontSize: '12px', padding: '5px 4px', backgroundColor: '#4846464d',
                                        borderRadius: '8px', textAlign: 'start', maxWidth: '100%'
                                    }}>
                                    How can I help you today?
                                </h6>
                            </div>
                        }
                        {dbchatresponse && dbchatresponse?.length > 0 && dbchatresponse.map((item, index) => {
                            return (
                                <div key={index} className={item.user_type == "Lender" ? 'd-flex justify-content-end' : 'd-flex justify-content-start'}>
                                    <h6 className='mx-2'
                                        style={item.user_type == "Lender" ?
                                            { fontSize: '12px', padding: '5px 4px', backgroundColor: 'rgb(188 228 255)', borderRadius: '8px', textAlign: 'start', maxWidth: '100%' } :
                                            { fontSize: '12px', padding: '5px 4px', backgroundColor: '#e8e8e8', borderRadius: '8px', textAlign: 'start', maxWidth: '100%' }}>
                                        {item.message}</h6>
                                </div>
                            )
                        })}
                        {viewAIdata && viewAIdata?.length > 0 && viewAIdata.map((item, index) => {
                            return (
                                <div key={index} className={item.isUser ? 'd-flex justify-content-end' : 'd-flex justify-content-start'}>
                                    <h6 className='mx-2' style={item.isUser ?
                                        { fontSize: '12px', padding: '5px 4px', backgroundColor: 'rgb(188 228 255)', borderRadius: '8px', textAlign: 'start', maxWidth: '100%' } :
                                        { fontSize: '12px', padding: '5px 4px', backgroundColor: '#e8e8e8', borderRadius: '8px', textAlign: 'start', maxWidth: '100%' }}>
                                        {item.message} </h6>
                                </div>
                            )
                        })}
                    </div>
                    {/* <div ref={messageEndRef} /> */}
                    <div className="addNoteBtnTxt">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-xm-12 pl-00 pr-0">
                            <form onSubmit={AddForm.handleSubmit}>
                                <div className="form-group mb-1 position-relative">
                                    <textarea
                                        placeholder='Ask something about...'
                                        className="form-control formtestarea"
                                        {...AddForm.getFieldProps('prompt')}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' && AddForm.values.prompt.replace(/\s/g, "").length > 0) {
                                                e.preventDefault();
                                                AddForm.handleSubmit();
                                                setHandleAPI(true)
                                            }
                                        }}
                                        style={{ paddingRight: '100px', marginBottom: '0px' }} // Add padding to avoid text overlap with button
                                    />
                                    <div className="position-absolute top-50 end-0 translate-middle">
                                        {spinnerStatus ? (
                                            <Loader status={spinnerStatus} />
                                        ) : (
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-lg"
                                                onClick={() => setHandleAPI(true)}
                                                disabled={AddForm.values.prompt.replace(/\s/g, "").length === 0}
                                            >
                                                Ask
                                                <i className="fa-solid fa-wand-magic-sparkles"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            }</div>
    )
}

export default MlAnalyser