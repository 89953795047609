import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import toast from 'react-hot-toast'
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as XLSX from 'xlsx';
import { getApi, postApi } from '../service/lenderService';
import Search from '../../components/Search/Search';
import Loader from '../../components/Loader/loader';
import PaginationComponent from '../../PaginationComponent';
import { Image } from 'antd';

const Reconciliation = () => {
    const closeButton = useRef()
    const openSeconModal = useRef()
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const [dataArray, setDataArray] = useState([])
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [search, setSearch] = useState('');
    const [sortedBy, setSortedBy] = useState("created_at")
    const [orderBy, setOrderBy] = useState('desc')
    const [uploadFile, setUploadFile] = useState(null)
    const inputref = useRef()
    const [handleStatus, setHandleStatus] = useState(false)
    const viewReconciliationDetail = async () => {
        try {
            const response = await getApi('/ewa-advance-reconciliation', { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
            if (response.status) {
                setTimeout(() => {
                    setDataArray(response?.data?.rows);
                    setTotalPagesCount(response?.data?.count)
                    setloader(false)
                }, 2000);

                setloader(true)
            } else {
                console.log(response.message)
                toast.error(response.message)
            }

        } catch (error) {
            console.log(error)
        }

    }

    useEffect(() => {
        viewReconciliationDetail()
    }, [search, pagesCount, sortedBy, orderBy])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    console.log(handleStatus, "fgdgfdgfdgdfgd")

    const HandleReminderFile = (e) => {
        const pdf = e.target.files[0];
        if (pdf && pdf.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            toast.error('Invalid file type. Please upload a valid Excel file (XLSX format).');
            e.target.value = '';
            setUploadFile(null);

            return;
        }
        setUploadFile(pdf);
        // readExcel(pdf)
        setHandleStatus(true)
    }

    const AddReminder = useFormik({
        initialValues: {
            isExcelFile: false,
        },
        enableReinitialize: true,
        validationSchema: yup.object({
            // reminderMessage: yup.string().required('Please Enter Message'),

        }),
        onSubmit: async (values) => {
            if (uploadFile) {
                const formData = new FormData();
                formData.append("file", uploadFile);
                const response = await postApi("/upload-repayment-update-sheet", formData, true);
                if (response.status == true) {
                    toast.success(response.message);
                    AddReminder.setFieldValue("isExcelFile", "")
                    closeButton.current.click()
                    // setExcelFile(null)   
                    openSeconModal.current.click()
                    inputref.current.value = ""
                    AddReminder.resetForm()
                    setHandleStatus(false)
                } else {
                    inputref.current.value = ""
                    toast.error(response.message);
                }

            }

        },
    })

    return (
        <div className="main-container container-fluid px-0">
            <div className="page-header mb-3">
                <div className="page-leftheader">
                    {/* <h4 className="page-title mb-0 text-primary">Paid</h4> */}

                    <h4 className="page-title mb-0 text-primary ">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a>EWA Advance</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Reconciliation</li>
                            </ol>
                        </nav>
                    </h4>
                </div>
                <div className="page-rightheader">
                    <button className='btn btn-primary me-2' data-bs-toggle="modal" data-bs-target="#staticBackdropmatch">Reconcile</button>
                    <div class="modal fade" id="staticBackdropmatch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-md">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h1 class="modal-title fs-5" id="staticBackdropLabel">Verify Document</h1>
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <form onSubmit={AddReminder.handleSubmit}>
                                        {/* <input type='file'></input> */}
                                        <div className="page-rightheader"/*  style={{ marginLeft: '10px' }} */>
                                            <div className="btn-list ">
                                                <label>Add File: {/* <span>Note</span> */}</label>
                                                <button className="btn btn-outline-primary uploadbmodal">
                                                    <label htmlFor="formFile">
                                                        <i className="fa fa-upload fa-beat me-2"></i>
                                                    </label>
                                                    <input ref={inputref} type="file" id='formFile' onChange={(e) => HandleReminderFile(e)} />
                                                </button>
                                                {AddReminder.touched.reminderMessage && AddReminder.errors.reminderMessage ?
                                                    <div className="invalid-feedback" style={{ display: "block" }}>{AddReminder.errors.reminderMessage}</div> : ''}
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" ref={closeButton} class="btn btn-dark" data-bs-dismiss="modal">Close</button>
                                            <button type="submit" class="btn btn-primary" disabled={!handleStatus} >Upload</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-xl-4 col-lg-6 col-md-12" />
                <div className="col-xl-4 col-lg-6 col-md-12" />
                <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="card visiableCard">
                        <div className="card-body p-0 visiableCard">
                            <div className="table-responsive">
                                <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                    <thead className="border-bottom-0 pt-3 pb-3">
                                    <tr>
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <th className="font-weight-bold">Loan ID</th>
                                        <th className="font-weight-bold">Customer Details  {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                        <th className="font-weight-bold">Update By Borrower {/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                        <th className="font-weight-bold">Amount to be Paid {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                        <th className="font-weight-bold">Description </th>
                                        <th>Attachment</th>
                                        <th className="font-weight-bold">Due Date </th>
                                    </tr>
                                    </thead>
                                    {loader == true ? <tbody>
                                        <tr><td /><td /> <td />
                                            <Loader /></tr>
                                    </tbody> :
                                        <>
                                             <tbody>
                                    <tr>
                                    <td><input type="checkbox" name={110} /* checked={checheked(110) === 1 ? true : false} onChange={(event) => selectTransactions(event, 110)} */ /></td>
                                        <td><span className="font-weight-normal1"> 110 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">Manish Pandey<br />646546545654</span></span></td>
                                        <td><span className="font-weight-normal1"> $54000</span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $50000</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth">You can replace $54000 with the actual payment amount and 110 with the specific loan identifier for clarity</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/loginbg.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-23 12:02 PM</span></td>

                                    </tr>
                                    <tr >
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <td><span className="font-weight-normal1"> 125 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">JOHN DOE<br />99898898</span></span></td>

                                        <td><span className="font-weight-normal1"> $30250.00</span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $30250.00</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth"> I have successfully made the EMI payment of $30250.00 for my personal loan today. Thank you for the seamless transaction process.</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/qr_code.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-23 16:02 PM</span></td>

                                    </tr>
                                    <tr >
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <td><span className="font-weight-normal1"> 221 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">Test india<br />9009892706</span></span></td>
                                        <td><span className="font-weight-normal1"> $735.00 </span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $588.00</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth"> Just completed my EMI payment of $735.00 for loan ID #221. Looking forward to continuing my repayment journey.</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/welldone.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-23 10:02 AM</span></td>
                                    </tr>
                                    <tr >
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <td><span className="font-weight-normal1"> 176 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">Sudhanshu khandelwal<br />9009892702</span></span></td>
                                        <td><span className="font-weight-normal1"> $3148.95 </span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $3148.94</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth"> EMI of $3148.95 for my home loan has been paid on time. I appreciate the easy payment options available</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-20 12:45 PM</span></td>
                                    </tr>
                                </tbody>
                                            {/* <tbody>
                                                {dataArray?.length == 0 &&
                                                    <tr>
                                                        <td colSpan={10} className='text-center'>No Data Available</td>
                                                    </tr>
                                                }
                                            </tbody> */}
                                        </>}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === true ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems=/* {totalPagesCount} */ "1"
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
                {pagesCount > 0 && dataArray?.length > 0 && ValidPagination === false ?
                    <>
                        <div className="col-md-12">
                            <div className="card-body">
                                <PaginationComponent className="justify-content-center"
                                    totalItems=/* {totalPagesCount} */"1"
                                    pageSize={10}
                                    maxPaginationNumbers={3}
                                    onSelect={(e) => setpagesCount(e)}
                                />
                            </div>
                        </div>
                    </>
                    : ''}
            </div>
            <button type="button" ref={openSeconModal} style={{ display: 'none' }} data-bs-toggle="modal" data-bs-target="#staticBackdrop">

            </button>
            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-xl modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">Updated Matching Data</h1>
                            <input className='form-control w-30 mt-1' placeholder='-- Search here--'></input>
                            {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            <button type="button" class="btn btn-outline-primary" data-bs-dismiss="modal"><i className='fas fa-close'></i></button>

                        </div>
                        <div class="modal-body">
                            <table className="table table-hover card-table table-vcenter text-nowrap" /* ref={tableRef} */>
                                <thead className="border-bottom-0 pt-3 pb-3">
                                    <tr>
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <th className="font-weight-bold">Loan ID</th>
                                        <th className="font-weight-bold">Customer Details  {/* {sortedBy == "customer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('customer_name') }}> </i>} */} </th>
                                        <th className="font-weight-bold">Update By Borrower {/* {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}  */}</th>
                                        <th className="font-weight-bold">Amount to be Paid {/* {sortedBy == "txn_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_amount') }}> </i>}  */}</th>
                                        <th className="font-weight-bold">Description </th>
                                        <th>Attachment</th>
                                        <th className="font-weight-bold">Due Date </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td><input type="checkbox" name={110} /* checked={checheked(110) === 1 ? true : false} onChange={(event) => selectTransactions(event, 110)} */ /></td>
                                        <td><span className="font-weight-normal1"> 110 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">Manish Pandey<br />646546545654</span></span></td>
                                        <td><span className="font-weight-normal1"> $54000</span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $50000</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth">You can replace $54000 with the actual payment amount and 110 with the specific loan identifier for clarity</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/loginbg.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-23 12:02 PM</span></td>

                                    </tr>
                                    <tr >
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <td><span className="font-weight-normal1"> 125 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">JOHN DOE<br />99898898</span></span></td>

                                        <td><span className="font-weight-normal1"> $30250.00</span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $30250.00</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth"> I have successfully made the EMI payment of $30250.00 for my personal loan today. Thank you for the seamless transaction process.</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/qr_code.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-23 16:02 PM</span></td>

                                    </tr>
                                    <tr >
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <td><span className="font-weight-normal1"> 221 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">Test india<br />9009892706</span></span></td>
                                        <td><span className="font-weight-normal1"> $735.00 </span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $588.00</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth"> Just completed my EMI payment of $735.00 for loan ID #221. Looking forward to continuing my repayment journey.</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/welldone.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-23 10:02 AM</span></td>
                                    </tr>
                                    <tr >
                                    <th><input type="checkbox" name="id" /* checked={isChecked} */ readOnly /* onClick={() => handleCheckClick()} */ /></th>
                                        <td><span className="font-weight-normal1"> 176 </span></td>
                                        <td><span className="font-weight-normal1" ><span className="font-weight-normal1">Sudhanshu khandelwal<br />9009892702</span></span></td>
                                        <td><span className="font-weight-normal1"> $3148.95 </span></td>
                                        <td><span className="font-weight-normal1 text-danger"> $3148.94</span></td>
                                        <td><span className="font-weight-normal1 tableDescriptionWidth"> EMI of $3148.95 for my home loan has been paid on time. I appreciate the easy payment options available</span></td>
                                        <td><Image style={{ width: 50, height: 35, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/Fintranxect-Logo-1.png'} alt='' /></td>
                                        <td><span className="font-weight-normal1">2025-01-20 12:45 PM</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="modal-footer">
                            {/* <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Close</button> */}
                            <button type="button" class="btn btn-primary">Reveived</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reconciliation