import React, { useEffect, useRef, useState } from 'react'
import { DownloadTableExcel } from 'react-export-table-to-excel'
import { Link, useNavigate } from 'react-router-dom'
import { GetPermission, getApi } from '../../service/lenderService';
import { Breadcrumbs } from '../../../components';
import PaginationComponent from '../../../PaginationComponent';
import Loader from '../../../components/Loader/loader';
import toast from 'react-hot-toast';
import UsePermissions from '../../layout/usePermission';
import Search from '../../../components/Search/Search';
const Pendingsettlement = () => {
    const permissionsStatus = UsePermissions();
    useEffect(() => {
    if(!permissionsStatus.payToMerchant){
        navigate('/lender/profile')
    }
},[permissionsStatus])
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [dataArray, setDataArray] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("customer_id")
    const [orderBy, setOrderBy] = useState('desc')
    const navigate = useNavigate();
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)


    const pendingsettlementdetail = async () => {
        // const response = await viewpendingsettlement(token, search, pagesCount, sortedBy, orderBy);
        const response = await getApi(`/merchant-dues`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy });
        // console.log(response)
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        }
        else {
            console.log(response)
        }

    }

    useEffect(() => {
        pendingsettlementdetail(token, search, pagesCount)
    }, [search, pagesCount, sortedBy, orderBy,ValidPagination])

    const [permission_data, setPermission_data] = useState([])

    const GetPermissions = async (token) => {
        try {
            const response = await GetPermission(token);
            console.log('response', response);
            if (response.status) {
                setPermission_data(response.data);
            }
        } catch (error) {
            // toast.error("error :", error.message);
            console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
        }
    }
    const user_types = JSON.parse(lenderData)
    const user_type = user_types.user_type

    useEffect(() => {
        if (user_type == "SUB-LENDERS") {
            GetPermissions(token);
        }
    }, []);
    const permissions = permission_data?.permissions;
    if (permissions) {
        var role = JSON.parse(permissions);
    } else {

    }
    const [merchant_pending_view, setmerchant_pending_view] = useState(false)
    const [merchant_pending_settlement_view, setmerchant_pending_settlement_view] = useState(false)
    const [merchant_add_settlement, setmerchant_add_settlement] = useState(false)

    useEffect(() => {
        if (role) {
            const options = role;
            console.log(options)
            options.length > 0 && options.forEach((option) => {
                if (option.key == "merchant_pending_view") {
                    setmerchant_pending_view(true);
                }
                if (option.key == "merchant_pending_settlement_view") {
                    setmerchant_pending_settlement_view(true);
                }
                if (option.key == "merchant_add_settlement") {
                    setmerchant_add_settlement(true);
                }
            });
        }
    }, [role]);

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("asc")
        }
        pendingsettlementdetail(token)
    }

    return (
        <>
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                {/* <h4 className="page-title mb-0 text-primary">Pending11 </h4> */}
                                <Breadcrumbs order={{ first: { name: "Pay to Merchant" }, second: { name: "Pending" } }} />

                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">


                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                    <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>

                                                        <th className="font-weight-bold"> &nbsp;&nbsp;&nbsp;Logo    </th>
                                                        <th className="font-weight-bold">Merchant Details  {sortedBy == "merchant_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Address  {sortedBy == "address" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('address') }}> </i>} </th>
                                                        {/* <th className="font-weight-bold">Company</th> */}
                                                        <th className="font-weight-bold">Pending Amount  {sortedBy == "balance" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('balance') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('balance') }}> </i>}</th>
                                                        <th className="font-weight-bold">ROI   {sortedBy == "roi" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('roi') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('roi') }}> </i>}</th>
                                                        {(user_type == 'lenders' || merchant_add_settlement || merchant_pending_settlement_view) && (<th className="font-weight-bold">Action</th>)}
                                                        {/* <th className="font-weight-bold"> &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;View TXN Detail   {sortedBy =="first_name" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('first_name')}} ></i> :<i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('first_name')}}> </i>}</th> */}
                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody>
                                                    <tr>
                                                        <td /><td />
                                                    <Loader />
                                                    </tr>                                                    
                                                </tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { merchant_id, merchant_name, mobile_number, roi, address, balance, logo } = option;
                                                                var num2 = parseFloat(option.interest_rate).toFixed(2)
                                                                var Capitalize = (option) => {
                                                                    return option.charAt(0).toUpperCase() + option.slice(1);
                                                                }
                                                                var timezone = new Date(option.created_at).toLocaleString(undefined, { timeZone: 'Asia/Kolkata', hour12: true });
                                                                return (

                                                                    <tr key={serial_num} >
                                                                        {logo != null ?
                                                                            <td><span className="font-weight-normal1"><img style={{ width: 50, height: 50, borderRadius: '8px' }} src={logo} alt='...' /></span></td> :
                                                                            <td><span className="font-weight-normal1" ><img style={{ width: 50, height: 50, borderRadius: '8px' }} src={process.env.PUBLIC_URL + '/assets/img/defaultMerchant.png'} alt='...' /></span></td>}
                                                                        {/* <Link className="text-primary" to={{pathname: `${prefix}/merchant_detail/${merchant_id}`, state: {merchant_id}}}> */}
                                                                        <td><span className="font-weight-normal1">{merchant_name ? merchant_name : ""}<br />{mobile_number}</span></td>
                                                                        <td><span className="font-weight-normal1">{address ? address : ""} </span></td>
                                                                        <td><span className="font-weight-normal1"> {balance ? "$" + balance : ""} </span></td>
                                                                        {/* <td><span className="font-weight-normal1">{option.mini_rate_of_intrest == null ? "N/A": mini_rate_of_intrest + "%"}</span></td> */}
                                                                        <td><span className="font-weight-normal1"> {roi ? roi + "%" : ""} </span></td>
                                                                        {(merchant_add_settlement || merchant_pending_settlement_view || user_type == 'lenders') && (<td><span className="font-weight-normal1"><Link to={prefix + '/addsettlement/' + merchant_id} className='btn btn-primary btn-md ms-3'>Settlment</Link></span></td>)}
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available for Settlement</td>
                                                                </tr>
                                                            }
                                                        </tbody></>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

{pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                        <>
                            <div className="col-md-12">
                                <div className="card-body">
                                    <PaginationComponent className="justify-content-center"
                                        totalItems={totalPagesCount}
                                        pageSize={10}
                                        maxPaginationNumbers={3}
                                        onSelect={(e) => setpagesCount(e)}
                                    />
                                </div>
                            </div>
                        </>
                        : ''}

                        </div>
                    </div>
                </div>

            }
        </>
    )
}

export default Pendingsettlement
