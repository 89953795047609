import React, { useEffect, useRef, useState } from 'react'
import PaginationComponent from '../../../PaginationComponent';
import { Link, useNavigate } from 'react-router-dom';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { getApi } from '../../service/lenderService'
import toast, { Toaster } from 'react-hot-toast';
import Loader from '../../../components/Loader/loader';
import CopyToClipboard from 'react-copy-to-clipboard';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import UsePermissions from '../../layout/usePermission';
import Search from '../../../components/Search/Search';

const Roi = () => {
    const permissionsStatus = UsePermissions();
    useEffect(() => {
        if (!permissionsStatus.ROI) {
            navigate('/lender/profile')
        }
    }, [permissionsStatus])
    // const [TotalCount, setTotalCount] = useState('');
    // const [totalinterest , setTotalinterest] = useState('')
    const [copied, setCopied] = useState(false);
    const [showAddNewCases, setshowAddNewCases] = useState(false);
    const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
    const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
    const [pagesCount, setpagesCount] = useState(1);
    const [totalPagesCount, setTotalPagesCount] = useState(10);
    const [totalItems, settotalItems] = useState(false);
    const [checkbox, setCheckbox] = useState(false)
    const [dataArray, setDataArray] = useState([]);
    const [transactionPrimaryId, setTransactionPrimaryId] = useState([]);
    const [search, setSearch] = useState('');
    const tableRef = useRef(null);
    const [refresh, setRefresh] = useState(true)
    const prefix = process.env.REACT_APP_LENDER_PRIFIX;
    const [sortedBy, setSortedBy] = useState("customer_id")
    const [orderBy, setOrderBy] = useState('desc')
    const [loader, setloader] = useState(true)
    const [ValidPagination, setValidPagination] = useState(false)
    const navigate = useNavigate();

    const ViewInvestment = async (token) => {
        const response = await getApi("/return-of-invesments", { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
        // const response = await ViewInvestmentdetails(token , search ,pagesCount,sortedBy,orderBy)        
        if (response.status == true) {
            setTimeout(() => {
                setDataArray(response.data.rows)
                setTotalPagesCount(response.data.count)
                //  setTotalCount(response.data.total_amount)
                //  setTotalinterest(response.data.total_interest) 
                setloader(false)
            }, 2000);
            setloader(true)
        } else if (response.message == 'Session expired') {
            localStorage.removeItem('lender');
            navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
        } else if (response.message == "Access denied! you're not allowed to access this tab") {
            toast.error(response.message)
            navigate('/lender/dashboard')
        } else {
            console.log(response)
        }
    }

    useEffect(() => {
        ViewInvestment(token, search, pagesCount)

    }, [search, pagesCount, refresh, sortedBy, orderBy, ValidPagination])

    const sortChange = (col) => {
        if (col === sortedBy) {
            setSortedBy(col);
            orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
        } else {
            setSortedBy(col)
            setOrderBy("desc")
        }
        // ViewInvestment(token, search, pagesCount )
    }
    const copiedInfo = () => {
        toast.success("Account No. copied Successfully")
    }

    // const updatesynch = async() => {
    //     setTransactionPrimaryId([])
    // const data = {
    //         order_id: transactionPrimaryId
    //     }
    //     const response = await axios.post("https://thirdroc.ai:8888/lender/sync-interest",(data), {
    //         headers: {
    //             "Authorization": `Bearer ${token}`
    //         },

    //     })
    //     console.log(response)
    //     if(response.data.status == true){
    //         toast.success("Emi Data sync Successfully");
    //         setCheckbox(false)
    //         settotalItems(false)
    //         setRefresh(false)

    //     }else{
    //         console.log(response)
    //     }
    // }


    // const updatesynch = async() => {
    //     const data = {
    //         order_id: transactionPrimaryId
    //     }
    //     console.log("treretrre" ,data)
    //     const response = await synchInvestment( data);
    //     console.log(response)
    //     if(response.data){
    //         console.log(response)
    //     }else {
    //         console.log(response.error)
    //     }

    // }



    const selectTransactions = async (id, e) => {
        if (!transactionPrimaryId.includes(id)) {
            setTransactionPrimaryId(oldArr => [...oldArr, id]);
            setCheckbox(true)
            settotalItems()

        } else {
            let x = transactionPrimaryId.shift(id)
            if (transactionPrimaryId.length == 0) {
                setCheckbox(false);
            }
        }
    }
    return (
        <>
            <Toaster position="top-right" reverseOrder={false} />
            {!showAddNewCases &&
                <div>
                    <div className="main-container container-fluid px-0">
                        <div className="page-header mb-3">
                            <div className="page-leftheader">
                                <h4 className="page-title mb-0 text-primary">ROI(Return Of Investment)</h4>
                                {/* {checkbox  ? <button className="btn btn-primary" onClick={updatesynch}>Sync</button>:
                                 <button className="btn btn-primary" disabled>Sync</button>} */}
                            </div>
                            {/* <div style={{ marginLeft: '360px' , marginTop: '10px' }}>
                            <h5 > {TotalCount ? `Total: $${TotalCount}` : ""}</h5></div>
                            <h5 > <b>{num3 ? `Interest:  $${num3}` : ""}</b></h5> */}
                            <div className="page-rightheader">
                                {/* <h6 className="ml-2"> Total Interest : $5647</h6> */}
                                <div className="btn-list">
                                    <DownloadTableExcel
                                        filename="users table"
                                        sheet="users"
                                        currentTableRef={tableRef.current}>
                                        <Link to="" className="btn btn-primary btn-pill"><i className="fa fa-download me-2"></i> Download Details</Link>
                                    </DownloadTableExcel>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

                                {/* <div className="form-group">
                                    <label className="form-label">All Status</label>
                                    <div className="row g-xs">
                                        <div className="wd-200 mg-b-30">
                                            <div className="input-group">
                                                <select className="form-control mb-0" name='submit'  value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)}>
                                                    <option>Select Status</option>
                                                    <option value="all task" >All Status</option>
                                                    <option value="Assigned"  >Assigned</option>
                                                    <option value="Unassigned" >Unassigned</option>

                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                            </div>
                            <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

                            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
                                <div className="form-group">
                                    <label className="form-label">Search</label>
                                    <div className="row g-xs">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                                            <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div className="table-responsive">
                                            <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                                                <thead className="border-bottom-0 pt-3 pb-3">
                                                    <tr>
                                                        <th className="font-weight-bold">Borrower Name  {sortedBy == "first_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('first_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Employer Detail  {sortedBy == "employer_name" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('employer_name') }}> </i>}</th>
                                                        <th className="font-weight-bold">Disbursed Limit  {sortedBy == "total_limit" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('total_limit') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('total_limit') }}> </i>}</th>
                                                        {/* <th className="font-weight-bold">Used Limit  {sortedBy =="used_limit" && orderBy==="desc" ? <i className='fas fa-long-arrow-alt-down' style={{fontSize : 10}} onClick={()=>{sortChange('used_limit')}} ></i> :<i className='fas fa-long-arrow-alt-up' style={{fontSize : 10}} onClick={()=>{sortChange('used_limit')}}> </i>}</th> */}
                                                        <th className="font-weight-bold">ROI  {sortedBy == "profit_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('profit_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('profit_amount') }}> </i>}</th>
                                                        <th className="font-weight-bold">Account No.  {sortedBy == "account_number" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('account_number') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('account_number') }}> </i>}</th>
                                                        <th className="font-weight-bold">Disbursed Date  {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}> </i>}</th>                                                    </tr>
                                                </thead>
                                                {loader == true ? <tbody><tr>
                                                    <td /><td /><Loader />
                                                </tr></tbody> :
                                                    <>
                                                        <tbody>
                                                            {dataArray.length > 0 && dataArray.map((option, index) => {
                                                                let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                                                                const { employer_id, employer_name, profit_amount, account_number, employer_mobile_number, total_limit, last_name, employer_contact_person, first_name, mobile_number, total_amount } = option;
                                                                var param = ""
                                                                if (total_amount == "") {
                                                                    param = "New"
                                                                } else {
                                                                    param = "Existing"
                                                                }
                                                                return (
                                                                    <tr key={serial_num} >
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/employee_detail/${mobile_number}/${param}`, state: { mobile_number } }}>
                                                                            <span className="font-weight-normal1">{first_name ? first_name : "--"}  {last_name ? last_name : ""}<br />{mobile_number}</span></Link></td>
                                                                        <td><Link className="text-primary" to={{ pathname: `${prefix}/employer_detail/${employer_id}/${param}`, state: { employer_id } }}>
                                                                            <span className="font-weight-normal1">{employer_name ? employer_name : "--"}
                                                                                <br />{employer_contact_person ? employer_contact_person : ""}
                                                                                <br />{employer_mobile_number ? (employer_mobile_number) : ""}</span></Link></td>
                                                                        <td><span className="font-weight-normal1"> {total_limit ? "$" + total_limit : "--"} </span></td>
                                                                        {/* <td><span className="font-weight-normal1"> {used_limit ? "$" + used_limit : "NA"} </span></td>                                                                */}
                                                                        <td><span className="font-weight-normal1" style={{ color: "#00c18d" }}> {profit_amount ? "$" + profit_amount : "--"} </span></td>
                                                                        <td><span className="font-weight-normal1">{account_number ? account_number : "--"}</span>
                                                                            <span>
                                                                                <CopyToClipboard text={account_number} onCopy={() => setCopied(true)}>
                                                                                    <button className='btn btn-primary btn-sm ms-3' onClick={() => copiedInfo()}><i className="fa-solid fa-copy"></i></button>
                                                                                </CopyToClipboard>
                                                                            </span>
                                                                        </td>
                                                                        <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            {dataArray.length == 0 &&
                                                                <tr>
                                                                    <td colSpan={10} className='text-center'>No Data Available</td>
                                                                </tr>
                                                            }
                                                        </tbody>
                                                    </>}
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                            {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
                                <>
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <PaginationComponent className="justify-content-center"
                                                totalItems={totalPagesCount}
                                                pageSize={10}
                                                maxPaginationNumbers={3}
                                                onSelect={(e) => setpagesCount(e)}
                                            />
                                        </div>
                                    </div>
                                </>
                                : ''}

                        </div>
                    </div>
                </div>

            }
        </>
    )

}
export default Roi
