import React, { useEffect, useRef, useState } from 'react'
import { GetPermission, getApi, postApi } from '../../service/lenderService';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast, { Toaster } from 'react-hot-toast';
import { Breadcrumbs } from '../../../components';
import Loader from '../../../components/Loader/loader';
import PaginationComponent from '../../../PaginationComponent';
import { TimeZone } from '../../../components/TimeZone/TimeZone';
import Search from '../../../components/Search/Search';
import UsePermissions from '../../layout/usePermission';

const Thirdrocsettlement = () => {
  const permissionsStatus = UsePermissions();
  useEffect(() => {
  if(!permissionsStatus.payToThirdroc){
      navigate('/lender/profile')
  }
},[permissionsStatus])
  const [lenderData, setDsaData] = useState(localStorage.getItem("lender"));
  const [token, setToken] = useState(JSON.parse(lenderData)?.lender_token);
  const [dataArray, setDataArray] = useState([]);
  const tableRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [multippleArray, setMultippleArray] = useState([]);
  const [totalAmoumt, setTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [disable, setDisable] = useState(false);
  const [imageUpload, setimageUpload] = useState(null)
  const [loader, setloader] = useState(true)
  const [search, setSearch] = useState('');
  const [pagesCount, setpagesCount] = useState(1);
  const [totalPagesCount, setTotalPagesCount] = useState(10);
  const [sortedBy, setSortedBy] = useState("id")
  const [orderBy, setOrderBy] = useState('desc')
  const [ValidPagination, setValidPagination] = useState(false)
  const [spinnerStatus, setSpinnerStatus] = useState(false)
  const [permission_data, setPermission_data] = useState([])
  const [thirdroc_pending_view, setthirdroc_pending_view] = useState(false)
  const [thirdroc_add_settlement, setthirdroc_add_settlement] = useState(false)
  const removeModal = useRef()
  const navigate = useNavigate();
  const user_types = JSON.parse(lenderData)
  const user_type = user_types.user_type

  const thirdrocsettlementdetail = async () => {
    const response = await getApi(`/thirdroc-dues`, { search: search, page_number: pagesCount, sortby: sortedBy, orderby: orderBy })
    // const response = await Thirdrocsettlementview(token, search, pagesCount, sortedBy, orderBy)
    if (response.status == true) {
      setTimeout(() => {
        setDataArray(response.data.rows)
        setTotalPagesCount(response.data.count)
        setloader(false)
      }, 2000);
      setloader(true)
    } else if (response.message == 'Session expired') {
      localStorage.removeItem('lender');
      navigate(process.env.REACT_APP_LENDER_PRIFIX + '/login')
    } else if (response.message == "Access denied! you're not allowed to access this tab") {
      toast.error(response.message)
      navigate('/lender/dashboard')
    }
  }

  const sortChange = (col) => {
    if (col === sortedBy) {
      setSortedBy(col);
      orderBy === "asc" ? setOrderBy("desc") : setOrderBy("asc")
    } else {
      setSortedBy(col)
      setOrderBy("asc")
    }
    thirdrocsettlementdetail(token)
  }

  useEffect(() => {
    thirdrocsettlementdetail()
  }, [search, pagesCount, sortedBy, orderBy, ValidPagination])

  const addSettlementForm = useFormik({
    initialValues: {
      merchantTransctionId: '',
      payment_mode: '',
      comment: "",
      image: ""
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      comment: yup.string().required('Please add a comment'),
      payment_mode: yup.string().required('Please add a payment mode '),
    }),
    onSubmit: async (values, { resetForm }) => {
      setSpinnerStatus(true)
      const formData = new FormData();
      formData.append("merchantTransctionId[]", multippleArray);
      formData.append("payment_mode", values.payment_mode);
      formData.append("comment", values.comment);
      formData.append("image", imageUpload);
      postApi("/thirdroc-transfer-payment", formData, true).then((res) => {
        if (res.status == true) {
          resetForm({ values: '' });
          addSettlementForm.resetForm()
          toast.success("Payment Done Successfully");
          thirdrocsettlementdetail(token)
          setimageUpload(null)
          setMultippleArray([])
          setTotalAmount(0)
          removeModal.current.click()
          setSpinnerStatus(false)
        } else if (res.status == false) {
          toast.error(res.message)
          setSpinnerStatus(false)
          // setMultippleArray([])
          // setTotalAmount(0)
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  });


  const selectTransactions = async (e, id, amount) => {
    let checkArray = await multippleArray;
    let rowIndex = checkArray.findIndex(v => v == id);

    if (rowIndex > -1) {
      let newAmount = parseFloat(totalAmoumt) - parseFloat(amount);
      setTotalAmount(newAmount)
      checkArray.splice(rowIndex, 1);

    } else {
      checkArray.push(id);
      let newAmount = parseFloat(totalAmoumt) + parseFloat(amount)
      setTotalAmount(newAmount)

    }

    setMultippleArray(checkArray);
    setDataArray((old_data) => [...old_data]);
    if (dataArray.length == multippleArray.length) {
      setIsChecked(true);
    }
    if (dataArray.length != multippleArray.length) {
      setIsChecked(false);
    }
  }

  function handleCheckClick() {
    if (isChecked == false) {
      let newdata = dataArray.map(v => parseInt(v.id)).join(',')
      let arrayData = newdata.split(',').map(Number);

      let newAmount = (dataArray.reduce((a, v) => a = a + parseFloat(v.thirdroc_income), 0))
      setTotalAmount(newAmount)
      setMultippleArray((old_data) => [...arrayData]);
      setIsChecked(true);
    } else {
      setTotalAmount(0)
      setIsChecked(false);
      setMultippleArray([]);
    }
    setDataArray((old_data) => [...old_data]);
  }

  const checheked = (item) => {
    return multippleArray.includes(item) ? 1 : 0;
  }

  const forCopy = () => {
    toast.success("Transaction ID Copied Successfully");

  }

  const handleInputChange = (event) => {
    addSettlementForm.handleChange(event);
    if (event?.target?.value) {
      setDisable(true)
    }
  }
  var num2 = parseFloat(totalAmoumt).toFixed(2)


  const GetPermissions = async (token) => {
    try {
      const response = await GetPermission(token);
      // console.log('response', response);
      if (response.status) {
        setPermission_data(response.data);
      }
    } catch (error) {
      console.error("🚀 ~ file: addEmployer.js:66 ~ GetCountry ~ error:", error)
    }
  }


  useEffect(() => {
    if (user_type == "SUB-LENDERS") {
      GetPermissions(token);
    }
  }, []);

  const permissions = permission_data?.permissions;
  if (permissions) {
    var role = JSON.parse(permissions);
  } else {

  }

  useEffect(() => {
    if (role) {
      const options = role;
      // console.log(options)
      options.length > 0 && options.forEach((option) => {
        if (option.key == "thirdroc_pending_view") {
          setthirdroc_pending_view(true);
        }
        if (option.key == "thirdroc_add_settlement") {
          setthirdroc_add_settlement(true);
        }

      });
    }
  }, [role]);

  return (
    <div>
      <Toaster position="top-right" reverseOrder={false} />
      <div className="main-container container-fluid px-0">
        <div className="page-header mb-3">
          <div className="page-leftheader">
            {/* <h4 className="page-title mb-0 text-primary">Pending</h4> */}

            <Breadcrumbs order={{ first: { name: "Pay to ThirdRoc" }, second: { name: "Pending" } }} />
          </div>
          <div style={{ marginLeft: '360px', marginTop: '10px' }}>
            <h5 > {totalAmoumt ? `Total: $${num2}` : ""}</h5></div>
          {(thirdroc_add_settlement || user_type == 'lenders') && <><div className='page-rightheader'>
            {dataArray.length == 0 ? "" :
              <button className='btn btn-primary' data-bs-target="#add_settlement"
                data-bs-toggle="modal" disabled={multippleArray.length > 0 ? false : true} >+ Add Settlement</button>}

          </div></>}
        </div>
        <div className="row">
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">

            {/* <div className="form-group">
                    <label className="form-label">All Status</label>
                    <div className="row g-xs">
                        <div className="wd-200 mg-b-30">
                            <div className="input-group">
                                <select className="form-control mb-0" name='submit'   value={setQueryFilter.filter}  onChange={(e) => handleChangeQueryBuilder(e.target.value)} >
                                    <option>Select Status</option>
                                    <option value="all task" >All Status</option>
                                    <option value="Assigned"  >Assigned</option>
                                    <option value="Unassigned" >Unassigned</option>

                                </select>
                            </div>
                        </div>
                    </div>
                </div> */}
          </div>
          <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
          </div>
          <Search search={search} setSearch={setSearch} setpagesCount={setpagesCount} setValidPagination={setValidPagination} ValidPagination={ValidPagination} />

          {/* <div className="col-xl-4 col-lg-6 col-md-6 col-xm-12">
            <div className="form-group">
              <label className="form-label">Search</label>
              <div className="row g-xs">
                <div className="input-group">
                  <input type="text" className="form-control" placeholder="Search By Keyword" value={search} onChange={(e) => setSearch(e.target.value)} />
                  <span className="input-group-append"> <button className="btn btn-primary" type="button"><i className="fa fa-search"></i></button> </span>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body p-0">
                <div className="table-responsive">
                  <table className="table table-hover card-table table-vcenter text-nowrap" ref={tableRef}>
                    <thead className="border-bottom-0 pt-3 pb-3">
                      <tr>
                        {(thirdroc_add_settlement || user_type == 'lenders') && <> {dataArray.length == 0 ? "" :
                          <th><input type="checkbox" name="id" checked={isChecked} readOnly onClick={() => handleCheckClick()} /></th>}</>}
                        {/* <th><input type="checkbox" name="id" checked={isChecked} readOnly  onClick={() => handleCheckClick()}  /></th> */}
                        {/* <th className="font-weight-bold">Sr No.</th> */}
                        <th className="font-weight-bold">Transaction ID {sortedBy == "txn_id" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('txn_id') }}> </i>}</th>
                        <th className="font-weight-bold">Paid Amount {sortedBy == "paid_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('paid_amount') }}> </i>}</th>
                        <th className="font-weight-bold">Merchant Amount {sortedBy == "merchant_settled_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('merchant_settled_amount') }}> </i>}</th>
                        <th className="font-weight-bold">ThirdRoc Income  {sortedBy == "thirdroc_income" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('thirdroc_income') }}> </i>}</th>
                        <th className="font-weight-bold">Lender Commission {sortedBy == "lender_commission_amount" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('lender_commission_amount') }}> </i>}</th>
                        <th className="font-weight-bold">Transaction Date {sortedBy == "created_at" && orderBy === "desc" ? <i className='fas fa-long-arrow-alt-down' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }} ></i> : <i className='fas fa-long-arrow-alt-up' style={{ fontSize: 10 }} onClick={() => { sortChange('created_at') }}> </i>}</th>
                        <th className="font-weight-bold">status</th>
                      </tr>
                    </thead>
                    {loader == true ? <tbody>
                      <tr>
                      <td /><td /><td />                      
                      <Loader />
                      </tr>
                    </tbody> :
                      <>
                        <tbody>
                          {dataArray && dataArray.map((option, index) => {
                            let serial_num = ((10 * (pagesCount - 1)) + index + 1);
                            const { txn_id, paid_amount, merchant_settled_amount, thirdroc_income, lender_commission_amount, thirdroc_roi, created_at, status } = option;

                            return (
                              <tr key={serial_num}>
                                {(thirdroc_add_settlement || user_type == 'lenders') && <td><input type="checkbox" name={option.id} checked={checheked(option.id) === 1 ? true : false} onChange={(e) => selectTransactions(e, option.id, option.thirdroc_income)} /></td>}
                                <td><span className="font-weight-normal1">{txn_id}</span>
                                  <span>
                                    <CopyToClipboard text={txn_id} onCopy={() => setCopied(true)} >
                                      <button className='btn btn-primary btn-sm ms-3' onClick={() => forCopy()}><i className="fa-solid fa-copy"></i></button>
                                    </CopyToClipboard>
                                  </span>
                                </td>
                                <td><span className="font-weight-normal1">{paid_amount ? "$" + paid_amount : ""}</span></td>
                                <td><span className="font-weight-normal1">{merchant_settled_amount ? "$" + merchant_settled_amount : ""}</span></td>
                                <td><span className="font-weight-normal1">{thirdroc_income ? "$" + thirdroc_income + "(" + thirdroc_roi + "%)" : ""} </span></td>
                                <td><span className="font-weight-normal1">{lender_commission_amount ? "$" + lender_commission_amount : ""}</span></td>
                                <td><span className="font-weight-normal1">{TimeZone(option?.created_at).timeZone}</span></td>
                                <td><span className="font-weight-normal1">{status}</span></td>
                              </tr>
                            )
                          })}
                        </tbody>
                        <tbody>
                          {dataArray.length == 0 && <tr>
                            <td colSpan={10} className='text-center'>No Data Available for Settlement</td>
                          </tr>}
                        </tbody>
                      </>}
                  </table>
                </div>
              </div>
            </div>
          </div>
          {pagesCount > 0 && dataArray.length > 0 && ValidPagination === true ?
            <>
              <div className="col-md-12">
                <div className="card-body">
                  <PaginationComponent className="justify-content-center"
                    totalItems={totalPagesCount}
                    pageSize={10}
                    maxPaginationNumbers={3}
                    onSelect={(e) => { setpagesCount(e); setTotalAmount(0); setIsChecked(false); setMultippleArray([]); }}
                  />
                </div>
              </div>
            </>
            : ''}
          {pagesCount > 0 && dataArray.length > 0 && ValidPagination === false ?
            <>
              <div className="col-md-12">
                <div className="card-body">
                  <PaginationComponent className="justify-content-center"
                    totalItems={totalPagesCount}
                    pageSize={10}
                    maxPaginationNumbers={3}
                    onSelect={(e) => { setpagesCount(e); setTotalAmount(0); setIsChecked(false); setMultippleArray([]); }}
                  />
                </div>
              </div>
            </>
            : ''}
        </div>
        <div className="modal fade effect-scale show" id="add_settlement" aria-modal="true" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content modal-content-demo">
              <div className="modal-header">
                <h3 className="modal-title">Settlement</h3>
                <button aria-label="Close" className="btn-close" ref={removeModal} data-bs-dismiss="modal" type="button"><span
                  aria-hidden="true">×</span></button>
              </div>
              <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                <h4> {totalAmoumt ? `Total: $${num2}` : ""}</h4>
              </div>
              <div className="modal-body application-modal">

                <form className="row" onSubmit={addSettlementForm.handleSubmit}>
                  {addSettlementForm.values.wrong_message ?
                    <div className="invalid-feedback mb-3" style={{ display: "block", textAlign: "center" }}>{addSettlementForm.values.wrong_message}</div>
                    : ''}
                  <div className="row">
                    <div className="row g-xs">
                      <div className="col-12">
                        <select className="form-control" name="payment_mode" {...addSettlementForm.getFieldProps("payment_mode")}
                          placeholder="Enter Payment Mode"  >
                          <option value="null">Select One</option>
                          <option value="cash" >Cash</option>
                          <option value="cheque">Cheque</option>
                          <option value="bank transfer">Bank Transfer</option>
                        </select>
                        {addSettlementForm.touched.payment_mode && addSettlementForm.errors.payment_mode ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.payment_mode}</div> : ''}
                      </div>
                      <div className="col-12">
                        <input type="text" onKeyUp={(e) => handleInputChange(e)} placeholder='comment' {...addSettlementForm.getFieldProps("comment")} value={addSettlementForm?.comment?.values} className="form-control" />
                        {addSettlementForm.touched.comment && addSettlementForm.errors.comment ?
                          <div className="invalid-feedback" style={{ display: "block" }}>{addSettlementForm.errors.comment}</div> : ''}
                      </div>
                      <div className="input-group file-browser mb-0">
                        <input type="file" className="form-control browse-file" placeholder="Upload your logo" onChange={(e) => setimageUpload(e.target.files[0])} />
                        {/* <label className="input-group-text btn btn-primary"> Browse <input type="file" className="file-browserinput"  onChange={(e) => setimageUpload(e.target.files[0])} style={{ display: 'none' }}  /> </label> */}
                        {/* <input type='file'  onChange={(e) => setimageUpload(e.target.files[0])} /> */}
                      </div>
                      {/* <img src={imageUpload} alt='...'/> */}
                    </div>
                  </div>
                  <div className="form-footer mt-2">
                    {spinnerStatus ? <button className="btn btn-primary" > <Loader status={spinnerStatus} /></button> :
                      <button type="submit" className="btn btn-primary" aria-hidden="true"
                      >Submit</button>}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Thirdrocsettlement
